import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

const BigTitle = () => {
    const data = useStaticQuery(graphql`
        query {
        contentfulAboutPage {
            aboutPageText {
                aboutPageText
            }
            aboutPageText2 {
                aboutPageText2
            }
            aboutPageText3 {
                aboutPageText3
            }
            aboutPageText4 {
                aboutPageText4
            }
        }
    }
`)
return (
        <div className='small-title-container'>
            <p className='small-title'>
                {data.contentfulAboutPage.aboutPageText.aboutPageText}
            </p>
            <p className='small-title'>
                {data.contentfulAboutPage.aboutPageText2.aboutPageText2}
            </p>
            <p className='small-title'>
                {data.contentfulAboutPage.aboutPageText3.aboutPageText3}
            </p>
            <p className='small-title'>
                {data.contentfulAboutPage.aboutPageText4.aboutPageText4}
            </p>
        </div>
    );
}

export default BigTitle;

