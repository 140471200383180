import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo";
import BigTitle from '../components/big-title';
import "../components/layout.css";

/*Component*/

const PageAbout = () => {
  return (
    <Layout>
    <Seo lang="English" title="About Old Farts Models" description="OF Models present the most popular seasoned male models in Finland. With a track record of thousands of commercials, catalogues and editorials as well as dominance on catwalks. In Finland and abroad. In Europe and in Asia. That's right. OF Models know what they're doing. Seasoned men do."/>
      <BigTitle/>
    </Layout>
  )
};

export default PageAbout; 